import {createApi} from "@reduxjs/toolkit/query/react";
import {ROUTER_ROUTE} from "routers/routers";
import {API_METHOD} from "types/api-method";
import {
  SignInWithEmailAndPasswordProps,
  SignInWithEmailAndPasswordResponse,
} from "types/api/auth/sign-in-with-email-and-password";
import {baseQuery} from "types/api/base-query";

const {SIGN_IN_WITH_EMAIL_AND_PASSWORD, REFRESH_TOKEN} = ROUTER_ROUTE;
const {POST} = API_METHOD;

export const USER_API_REDUCER_KEY = "authApi";

export const authAPI = createApi({
  reducerPath: USER_API_REDUCER_KEY,
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    signInWithEmailAndPassword: builder.mutation<
      SignInWithEmailAndPasswordResponse,
      SignInWithEmailAndPasswordProps
    >({
      query: data => {
        return {
          url: SIGN_IN_WITH_EMAIL_AND_PASSWORD,
          method: POST,
          data,
        };
      },
    }),
    refreshToken: builder.mutation<any, any>({
      query: data => {
        return {
          url: REFRESH_TOKEN,
          method: POST,
          data,
        };
      },
    }),
  }),
});

export const authAPIReducer = authAPI.reducer;

export const {useSignInWithEmailAndPasswordMutation, useRefreshTokenMutation} =
  authAPI;
