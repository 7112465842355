import {notification} from "antd";
import "./Notification.scss";

type NotificationProps = {
  status: "error" | "warning" | "success" | "info";
  message: string;
};

const Notification = ({status, message}: NotificationProps) => {
  return notification[status]({
    message: message,
    placement: "bottomLeft",
    duration: 4,
    className: "notification",
  });
};

export {Notification};
