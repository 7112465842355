import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { gradingApi } from "store/api/grading";
import { ResultRes } from "types/api/result";

export const init: ResultRes = {
  data: null,
};

export const resultExamSlice = createSlice({
  name: "resultExam",

  initialState: init,

  reducers: {},

  extraReducers: (builder) => {
    builder.addMatcher(
      gradingApi.endpoints.gradingToeic.matchFulfilled,
      (state, action) => {
        state.data = action.payload.data;
      }
    );
    builder.addMatcher(
      gradingApi.endpoints.gradingToeic.matchRejected,
      (state) => {
        state.data = null;
      }
    );
  },
});

export const ResultExamActions = resultExamSlice.actions;

export const resultExamReducer = persistReducer(
  {
    key: "rtk:result-exam",
    storage,
    whitelist: ["result-exam"],
  },
  resultExamSlice.reducer
);
