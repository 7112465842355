import "./HeaderPage.scss";
import { Avatar, Button, Dropdown, Image, Row } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { ROUTER_ROUTE, USER_ROUTE } from "routers/routers";
import { setCollapsed } from "store/global/global.slice";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect } from "react";
import {
  FileTextFilled,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { selectUserData, userActions } from "store/auth/user";
const HeaderPage = (props) => {
  const { username } = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const collapsed = useSelector((state: RootState) => state.global.collapsed);
  useEffect(() => {
    dispatch(setCollapsed(true));
  }, []);

  const items: any["items"] = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            dispatch(userActions.signOut());
            navigate(ROUTER_ROUTE.LOGIN);
          }}
        >
          Đăng xuất
        </a>
      ),
      icon: <LogoutOutlined />,
    },
    // {
    //   key: "2",
    //   label: (
    //     <a onClick={() => navigate(USER_ROUTE.ADMIN_MANAGER)}>
    //       Quản lý trang Admin
    //     </a>
    //   ),
    //   icon: <FileTextFilled />,
    // },
  ];
  return (
    <div className="header-page">
      {/* Trang chủ */}
      <Row justify={"space-between"} style={{ width: "100%" }}>
        <div className="collapsed-btn">
          <Button
            type="text"
            shape="circle"
            onClick={() => {
              dispatch(setCollapsed(!collapsed));
            }}
            icon={<MenuOutlined />}
            size="large"
          />
        </div>
        <div className="header-logo">
          <Image
            src={Logo}
            alt="logo"
            height="50px"
            width={"150px"}
            preview={false}
          />
          {/* <p>CHINH PHỤC TIÊNG ANH VỚI 3 CÔNG THỨC</p> */}
        </div>
        <div className="button-login">
          <Dropdown
            arrow={true}
            menu={{ items }}
            placement="bottomLeft"
            trigger={["click"]}
          >
            <Avatar className="avatar-user" shape="square">
              {username?.charAt(0).toLocaleUpperCase()}
            </Avatar>
          </Dropdown>
        </div>
      </Row>
    </div>
  );
};
export default HeaderPage;
