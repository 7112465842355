import "./HeaderPage.scss";
import { Avatar, Button, Col, Dropdown, Row, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { USER_ROUTE } from "routers/routers";
import { useRef } from "react";
import { selectUser, userActions } from "store/auth/user";
import ModalLoginCandidate from "pages/UserPage/ModalRegister/ModalLoginCandidate";
import {
  FileTextFilled,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import logo from "assets/images/logo.png";
import { MenuProps } from "antd/lib";
import {
  candidateActions,
  selectCandidate,
  selectCandidateData,
} from "store/auth/candidate";

const HeaderPageUser = (props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { fullName } = useAppSelector(selectCandidateData);
  const { accessToken } = useAppSelector(selectCandidate);

  const registerRef = useRef<any>();
  const loginRef = useRef<any>();

  const items: any["items"] = [
    {
      key: "1",
      label: (
        <a onClick={() => dispatch(candidateActions.signOut())}>Đăng xuất</a>
      ),
      icon: <LogoutOutlined />,
    },
    {
      key: "2",
      label: (
        <a onClick={() => navigate(USER_ROUTE.GRADE_EXAM_LIST)}>
          Lịch sử bài thi
        </a>
      ),
      icon: <FileTextFilled />,
    },
  ];

  const itemsLogin: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          type="default"
          onClick={() => {
            registerRef.current.open();
          }}
          icon={<i className="fa-solid fa-pen"></i>}
          style={{ width: "100%" }}
        >
          Đăng ký
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="primary"
          onClick={() => {
            loginRef.current.open();
          }}
          icon={<i className="fa-solid fa-user"></i>}
        >
          Đăng nhập
        </Button>
      ),
    },
  ];

  return (
    <div className="header-page-user">
      {/* Trang chủ */}
      <Row
        style={{
          width: "100%",
        }}
        justify={"space-between"}
      >
        <div className="img-logo">
          <img src={logo} onClick={() => navigate(USER_ROUTE.HOME)} />
        </div>

        <div className="slogan-text">SIMPLE ENGLISH CENTER</div>

        <div className="button-login">
          {!accessToken ? (
            <>
              <Row gutter={16} className="btn-laptop">
                <Col>
                  <Button
                    type="default"
                    onClick={() => {
                      registerRef.current.open();
                    }}
                    icon={<i className="fa-solid fa-pen"></i>}
                  >
                    Đăng ký
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      loginRef.current.open();
                    }}
                    icon={<i className="fa-solid fa-user"></i>}
                  >
                    Đăng nhập
                  </Button>
                </Col>
              </Row>
              <Dropdown
                className="dropdown-mobile"
                arrow={true}
                menu={{ items: itemsLogin }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <MenuOutlined />
              </Dropdown>
            </>
          ) : (
            <div className="username">
              <Typography.Text
                strong
                className="username-text"
              >{`${fullName} `}</Typography.Text>
              <Dropdown
                arrow={true}
                menu={{ items }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <Avatar className="avatar-user" shape="square">
                  {fullName?.charAt(0).toLocaleUpperCase()}
                </Avatar>
              </Dropdown>
            </div>
          )}
        </div>
      </Row>
      <ModalLoginCandidate ref={registerRef} type={"REGISTER"} />
      <ModalLoginCandidate ref={loginRef} type={"LOGIN"} />
    </div>
  );
};
export default HeaderPageUser;
