import {v1 as randomId} from "uuid";
const renderSTT = (index, page, limit) => {
  return index + 1 + (page - 1) * limit;
};

const isValuable = value => {
  if (value != null && value != undefined) {
    return true;
  } else {
    return false;
  }
};
export {renderSTT, isValuable, randomId};
