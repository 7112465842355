import {Breadcrumb, BreadcrumbProps} from "antd";
import {useAppSelector} from "hooks";
import React, {useEffect, useState} from "react";
// import "components/ButtonCustom/style.scss";
import {Link, useLocation} from "react-router-dom";
import {MAIN_ROUTE} from "routers/config-route";
import "./style.scss";
type Props = {
  _items: Array<{
    path: string;
    label: string;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }>;
};

const checkLabelBreadcrumb = (label: string | undefined) => {
  if (!label) {
    return "";
  }
  return label;
};

const BreadcrumbCustom: React.FC<Props & BreadcrumbProps> = ({
  _items,
  ...rest
}) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([]);
  const location = useLocation();
  const siderKey = useAppSelector(e => e.global.siderKey);

  useEffect(() => {
    let breadCrumb = "";
    const crumbs = location.pathname
      .split("/")
      .filter(crumb => crumb !== "")
      .map((crumb, i) => {
        breadCrumb = breadCrumb + `/${crumb}`;

        return {
          title: (
            <Link to={breadCrumb}>
              {checkLabelBreadcrumb(
                MAIN_ROUTE.find(el => el.path === breadCrumb)?.label
              )}
            </Link>
          ),
        };
      });
    setBreadcrumbItems(
      crumbs.filter(
        el => el.title.props.children !== "" || el.title.props.children
      )
    );
  }, [location, siderKey]);

  return (
    <div className="breadcrumb">
      {breadcrumbItems.length > 0 && (
        <Breadcrumb {...rest} items={breadcrumbItems} />
      )}
    </div>
  );
};

export default BreadcrumbCustom;
