import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { partApi } from "store/api/part";
import { ResponsePartProps } from "types/api/part";

export const userInitialState: ResponsePartProps = {
  data: [],
};

export const detailPartSlice = createSlice({
  name: "detailPart",

  initialState: userInitialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addMatcher(
      partApi.endpoints.getListPartByParams.matchFulfilled,
      (state, action) => {
        console.log(action.payload.data);

        state.data = action.payload.data;
      }
    );
    builder.addMatcher(
      partApi.endpoints.getListPartByParams.matchRejected,
      (state) => {
        state.data = [];
      }
    );
  },
});

export const DetailPartActions = detailPartSlice.actions;

export const detailPartReducer = persistReducer(
  {
    key: "rtk:detail-part",
    storage,
    whitelist: ["detail-part"],
  },
  detailPartSlice.reducer
);
