import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
export interface AdminState {
  userId: any;
  examId: any;
  examinee: any;
}

const initialState: AdminState = {
  userId: null,
  examId: null,
  examinee: null,
};

export const gradeEssaySlice = createSlice({
  name: "gradeEssay",
  initialState,
  reducers: {
    setUserId: (state: AdminState, action: PayloadAction<any>) => {
      state.userId = action.payload;
    },
    setExamId: (state: AdminState, action: PayloadAction<any>) => {
      state.examId = action.payload;
    },
    setExaminee: (state: AdminState, action: PayloadAction<any>) => {
      state.examinee = action.payload;
    },
  },
});

export const {setExamId, setUserId, setExaminee} = gradeEssaySlice.actions;

export const gradeEssayReducer = persistReducer(
  {
    key: "rtk:gradeEssay",
    storage,
    whitelist: ["examId", "userId", "examinee"],
  },
  gradeEssaySlice.reducer
);
