import {combineReducers, configureStore} from "@reduxjs/toolkit";

import globalSliceReducer from "./global/global.slice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {authAPI, authAPIReducer} from "./api/auth";
import {userReducer, userSlice} from "./auth/user";
import {adminReducer, adminSlice} from "./admin/admin";
import {courseApi, courseApiReducer} from "./api/course";
import {examApi, examApiReducer} from "./api/exam";
import {topicApi, topicApiReducer} from "./api/topic";
import {partApi, partApiReducer} from "./api/part";
import {fileApi, fileApiReducer} from "./api/file";
import {globalSlice} from "./global/global.slice";
import {detailExamReducer, detailExamSlice} from "./slice/detail-exam";
import {questionApi, questionApiReducer} from "./api/question";
import {detailPartReducer, detailPartSlice} from "./slice/detail-part";
import {gradingApi, gradingReducer} from "./api/grading";
import {gradingEssayApi, gradingEssayReducer} from "./api/grading-essay";
import {gradeEssayReducer, gradeEssaySlice} from "./gradeEssay";
import {resultExamReducer, resultExamSlice} from "./slice/result-exam";
import {
  detailResultQuestionApi,
  detailResultQuestionReducer,
} from "./api/detail-result-question";
import {candidateAPI, candidateAPIReducer} from "./api/candidate";
import {candidateReducer, candidateSlice} from "./auth/candidate";
import {activeUserApi, activeUserApiReducer} from "./api/active-password";
import {
  questionInExamApi,
  questionInExamApiReducer,
} from "./api/question_in_exam";
import {userApi, userApiReducer} from "./api/user";

const rootStore = {
  [authAPI.reducerPath]: authAPIReducer,
  [courseApi.reducerPath]: courseApiReducer,
  [examApi.reducerPath]: examApiReducer,
  [topicApi.reducerPath]: topicApiReducer,
  [partApi.reducerPath]: partApiReducer,
  [questionApi.reducerPath]: questionApiReducer,
  [fileApi.reducerPath]: fileApiReducer,
  [userApi.reducerPath]: userApiReducer,
  [userSlice.name]: userReducer,
  [adminSlice.name]: adminReducer,
  [globalSlice.name]: globalSliceReducer,
  [detailPartSlice.name]: detailPartReducer,
  [detailExamSlice.name]: detailExamReducer,
  [gradingApi.reducerPath]: gradingReducer,
  [gradingEssayApi.reducerPath]: gradingEssayReducer,
  [gradeEssaySlice.name]: gradeEssayReducer,
  [resultExamSlice.name]: resultExamReducer,
  [detailResultQuestionApi.reducerPath]: detailResultQuestionReducer,
  [candidateAPI.reducerPath]: candidateAPIReducer,
  [candidateSlice.name]: candidateReducer,
  [activeUserApi.reducerPath]: activeUserApiReducer,
  [questionInExamApi.reducerPath]: questionInExamApiReducer,
};

// const rootReducer = combineReducers({
//   ...rootStore,
// });

const appReducer = combineReducers({
  ...rootStore,
});

const rootReducer = (state, action) => {
  if (action.type === "user/signOut") {
    storage.removeItem("persist:root");
    localStorage.clear();
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authAPI.middleware,
      courseApi.middleware,
      examApi.middleware,
      topicApi.middleware,
      partApi.middleware,
      questionApi.middleware,
      fileApi.middleware,
      gradingApi.middleware,
      detailResultQuestionApi.middleware,
      candidateAPI.middleware,
      gradingEssayApi.middleware,
      activeUserApi.middleware,
      questionInExamApi.middleware,
      userApi.middleware,
    ]),
  devTools: process.env.REACT_NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
