import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./store/store";
import viVn from "antd/locale/vi_VN";
import { PersistGate } from "redux-persist/integration/react";
import ThemeRoutes from "./routers";
import "./style.scss";
import Splash from "pages/core/splash";
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={<Splash />} persistor={persistor}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#1D5DB1",
                colorSplit: "#757575e0",
              },
            }}
            locale={viVn}
          >
            <ThemeRoutes />
          </ConfigProvider>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
