import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import { baseQuery } from "types/api/base-query";
import { GetPartProps, PartProps, ResponsePartProps } from "types/api/part";

const { POST, PUT, DELETE } = API_METHOD;

export const PART_API_REDUCER_KEY = "partApi";

export const partApi = createApi({
  reducerPath: PART_API_REDUCER_KEY,
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    postPart: builder.mutation<PartProps, any>({
      query: (data) => ({
        url: apiPath.POST_PART,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    putPart: builder.mutation<PartProps, any>({
      query: (data) => ({
        url: apiPath.PUT_PART,
        method: PUT,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    delelePart: builder.mutation<ResponsePartProps, any>({
      query: (id) => ({
        url: `${apiPath.DELETE_PART}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
    getListPartByParams: builder.query<ResponsePartProps, GetPartProps>({
      query: (data) => ({
        url: data.testPaperId && `${apiPath.GET_LIST_PART}${serialize(data)}`,
      }),
      providesTags: ["Post"],
    }),
  }),
});

export const partApiReducer = partApi.reducer;

export const {
  usePostPartMutation,
  useGetListPartByParamsQuery,
  usePutPartMutation,
  useDelelePartMutation,
} = partApi;
