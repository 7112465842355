import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {serialize} from "helper/parse";
import {API_METHOD} from "types/api-method";
import {baseQuery, baseQueryWithReauth} from "types/api/base-query";
import {ExamProps, ExamResponse} from "types/api/exam";

const {POST, PUT, DELETE} = API_METHOD;

export const EXAM_API_REDUCER_KEY = "examApi";

export const examApi = createApi({
  reducerPath: EXAM_API_REDUCER_KEY,
  refetchOnMountOrArgChange: true,
  baseQuery,
  tagTypes: ["Post"],
  endpoints: builder => ({
    postExam: builder.mutation<ExamResponse, any>({
      query: data => ({
        url: apiPath.POST_EXAM,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    putExam: builder.mutation<ExamResponse, any>({
      query: data => ({
        url: apiPath.PUT_EXAM,
        method: PUT,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    deleleExam: builder.mutation<ExamResponse, any>({
      query: id => ({
        url: `${apiPath.DELETE_EXAM}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
    getListExamById: builder.query<ExamResponse, ExamProps>({
      query: data => ({
        url: `${apiPath.GET_LIST_EXAM}${serialize(data)}`,
      }),
      transformErrorResponse: (response: any) => response.error,
      providesTags: ["Post"],
    }),
  }),
});

export const examApiReducer = examApi.reducer;

export const {
  usePostExamMutation,
  useGetListExamByIdQuery,
  usePutExamMutation,
  useDeleleExamMutation,
} = examApi;
