import Loading from "pages/core/loading";
import Splash from "pages/core/splash";
import loadable from "@loadable/component";

type LazyProps = {
  path: string;
  fullScreen?: boolean;
  [name: string]: unknown;
};

/**
 * Lazy import component
 */
const lazy = ({
  path,
  fullScreen = false,
  ...props
}: LazyProps): JSX.Element => {
  const Element = loadable(
    () => import(/* webpackChunkName: "[request]" */ `pages/${path}`),
    {
      fallback: fullScreen ? <Splash /> : <Loading />,
    }
  );

  return <Element {...props} />;
};

export default lazy;
