import dayjs from "dayjs";
import { FORMAT_TIME } from "types/enums/format-time";
import _parseHtmlToString from "html-react-parser";
const parse = () => {};
const toArray = (data: any) => {
  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
};
const toNumber = (value, fixed = 0) => {
  return !isNaN(value) ? Number(Number(value).toFixed(fixed)) : 0;
};
const serialize = (obj: any) => {
  let str: string[] = [];
  if (typeof obj != "object") {
    return "";
  }
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      if (obj[p] || obj[p] === 0 || obj[p] === false)
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return "?" + str.join("&");
};

const toQueryString = (obj) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value) => params.append(key, value));
    } else {
      params.append(key, obj[key]);
    }
  }
  return params.toString();
};

const formatDateToString = (
  date: Object | string | null,
  format: FORMAT_TIME | string | null = null
) => {
  const validDate = dayjs(date?.toString())?.isValid();
  return date && validDate
    ? format
      ? format === FORMAT_TIME.unix
        ? dayjs(date?.toString()).unix()
        : dayjs(date?.toString()).format(format as any)
      : dayjs(date?.toString()).format()
    : null;
};
const convertDateStringToDateObject = (
  dateString: string | null,
  unix = false
) => {
  return dateString && dayjs(dateString)?.isValid()
    ? unix
      ? dayjs.unix(toNumber(dateString))
      : dayjs(dateString)
    : null;
};
const parseHtmlToString = (value) => {
  return typeof value == "string" ? _parseHtmlToString(value?.toString()) : "";
};
export {
  parse,
  serialize,
  formatDateToString,
  convertDateStringToDateObject,
  toQueryString,
  toArray,
  parseHtmlToString,
};
