enum FORMAT_TIME {
  dayFull = "DD/MM/YYYY",
  dayFull2 = "DD-MM-YYYY",
  dateTime = "HH:mm DD/MM/YYYY",
  dateTimes = "HH:mm:ss DD/MM/YYYY",
  dateTime_3 = "HH:mm:ss - DD/MM/YYYY",
  timeDetail = "YYYY-MM-DDTHH:mm:ss.SSSZ",
  time_24h = "HH:mm",
  time_24h_mili = "HH:mm:ss",
  hour = "HH",
  dayMonthYear = "[Ngày] DD [tháng] MM [năm] YYYY",
  unix = "unix",
}
export {FORMAT_TIME};
