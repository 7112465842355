import { FC, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { USER_ROUTE } from "./routers";
import { useAppSelector } from "hooks";
// import { setIsLogin } from "../store/auth/auth.slice";
// import MainRoutes from "./main-routers";
import {
  // selectUser,
  // selectUserData,
  selectUserRoles,
  // userActions,
} from "store/auth/user";
// import { SETTINGS_CONFIG } from "./settings";
// import checkRoles from "utils/check-roles";
// import checkToken from "utils/check-token";
// import { ROLE } from "types/roles";
// import { isReadable } from "stream";

// type RouterProps = {
//   module?: string;
//   action?: string;
//   requireLogin?: boolean;
// };

const RoleOutlet: FC<any> = (props) => {
  const { listRoleRequired } = props;
  const userRoles = useAppSelector(selectUserRoles);
  const navigate = useNavigate();
  const [access, setAccess] = useState(true);

  useEffect(() => {
    try {
      const isValidRole = listRoleRequired?.some((e) => userRoles.includes(e));
      setAccess(isValidRole);
    } catch (error) {
      console.log(error);
      // setAccess(false);
    }
    // console.log(access);
  }, [userRoles]);

  useEffect(() => {
    if (!access) {
      navigate(USER_ROUTE.HOME);
    }
  }, [access]);

  return <Outlet />;
};

export default RoleOutlet;
