import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Divider, Modal, ModalFuncProps } from "antd";
import "./ModalCustom.scss";
import { setIsModalOpenRedux } from "store/global/global.slice";
import { useAppDispatch } from "hooks";
interface ModalCustomProps extends ModalFuncProps {
  onCloseModal?: () => void;
  onOpenModal?: () => void;
  children: ReactNode;
  loading?: boolean;
}

export interface ModalCustomHandle {
  openModal: () => void;
  closeModal: () => void;
}
const ModalCustom = React.forwardRef<ModalCustomHandle, ModalCustomProps>(
  (props, ref: any) => {
    const { onCloseModal, onOpenModal, ...modalProps } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    useImperativeHandle(ref, () => ({
      openModal: () => {
        openModal();
      },
      closeModal: () => {
        closeModal();
      },
    }));

    const openModal = () => {
      setIsModalOpen(true);
      dispatch(setIsModalOpenRedux(true));
    };

    const closeModal = () => {
      dispatch(setIsModalOpenRedux(false));

      setIsModalOpen(false);
    };
    useEffect(() => {
      if (isModalOpen && onOpenModal) {
        onOpenModal();
      }
      if (!isModalOpen && onCloseModal) {
        onCloseModal();
      }
    }, [isModalOpen, onCloseModal, onOpenModal]);

    return (
      <div className="modal">
        <Modal
          wrapClassName="modal-custom"
          centered
          open={isModalOpen}
          onCancel={closeModal}
          // footer={null}
          width={"70%"}
          okText="Lưu lại"
          cancelText="Huỷ bỏ"
          {...modalProps}
        >
          {/* {React.cloneElement(props.children as React.ReactElement<any>, {
            closeModal: closeModal,
            openModal: openModal,
          })} */}
          {props.children}
        </Modal>
      </div>
    );
  }
);

export default ModalCustom;
