import {FC, useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {ROUTER_ROUTE} from "./routers";
import {useAppSelector} from "hooks";
// import { setIsLogin } from "../store/auth/auth.slice";
// import MainRoutes from "./main-routers";

// import { SETTINGS_CONFIG } from "./settings";
// import checkRoles from "utils/check-roles";
import checkToken from "utils/check-token";
import {selectUser, userActions} from "store/auth/user";
import {useDispatch} from "react-redux";
import {authAPI} from "store/api/auth";
import {handleRefreshToken} from "types/api/base-query";

// type RouterProps = {
//   module?: string;
//   action?: string;
//   requireLogin?: boolean;
// };

const ProtectedOutlet: FC = () => {
  const {accessToken} = useAppSelector(selectUser);
  const navigate = useNavigate();
  const [access, setAccess] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleToken = async () => {
      try {
        const isValidToken = checkToken(accessToken);

        if (isValidToken) {
          setAccess(isValidToken);
        } else {
          const isSuccessRefreshToken = await handleRefreshToken();
          if (!isSuccessRefreshToken) {
            dispatch(userActions.signOut());
          }
        }
      } catch (error) {
        // setAccess(false);
      }
    };

    handleToken();
    // console.log(access);
  }, [accessToken]);

  useEffect(() => {
    if (!access) {
      navigate(ROUTER_ROUTE.LOGIN);
    }
  }, [access, navigate]);

  return <Outlet></Outlet>;
};

export default ProtectedOutlet;
