import { RouteObject, useRoutes } from "react-router-dom";
import MainRoutes from "./main-routers";
// import { useAppSelector } from "../hooks";
import { useCallback, useMemo } from "react";

const ThemeRoutes = () => {
  // const {permission} = true;

  const getPermisisonRouter = useCallback((item: any): RouteObject => {
    let ans: RouteObject = item;

    const childs: any = [];
    if (Array.isArray(item?.children) && item?.children?.length > 0)
      item?.children.forEach((i: any) => {
        const _item: RouteObject = getPermisisonRouter(i);
        if (_item) childs.push(_item);
      });

    if (childs.length)
      ans = {
        ...ans,
        ...{
          children: childs,
        },
      };

    return ans;
  }, []);

  //memoized
  const ans = useMemo(() => {
    const finalRouter = (routes: Array<any>): Array<RouteObject> => {
      const ans: Array<RouteObject> = routes.map((i) => getPermisisonRouter(i));
      return ans;
    };
    return finalRouter(MainRoutes);
  }, [getPermisisonRouter]);

  return useRoutes(ans);
};

export default ThemeRoutes;
