//Exam
const POST_QUESTION = "/question/create";
const PUT_QUESTION = "/question/update";
const GET_DETAIL_BY_ID = "/question/by_id";
const GET_LIST_QUESTION = "/question/paging_search";
const GET_ALL_QUESTION = "/question/get_for_exam";
const DELETE_QUESTION = "/question/delete";

export const question = {
  POST_QUESTION,
  PUT_QUESTION,
  GET_LIST_QUESTION,
  GET_ALL_QUESTION,
  DELETE_QUESTION,
  GET_DETAIL_BY_ID,
};
