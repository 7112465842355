import {createApi} from "@reduxjs/toolkit/query/react";
import {apiPath} from "constants/apiPath";
import {serialize} from "helper/parse";
import {API_METHOD} from "types/api-method";
import {baseQuery} from "types/api/base-query";
import {FileProps, GetFileProps} from "types/api/file";

const {POST, DELETE} = API_METHOD;

export const FILE_API_REDUCER_KEY = "fileApi";

export const fileApi = createApi({
  reducerPath: FILE_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Post"],
  endpoints: builder => ({
    postFile: builder.mutation<FileProps, any>({
      query: data => ({
        url: apiPath.POST_FILE,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),

    deleteFile: builder.mutation<any, any>({
      query: id => ({
        url: `${apiPath.DELETE_FILE}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
    getFileByParams: builder.query<any, GetFileProps>({
      query: data => ({
        url: `${apiPath.GET_FILE}${serialize(data)}`,
      }),
      transformErrorResponse: (response: any) => response.error,
      providesTags: ["Post"],
    }),
  }),
});

export const fileApiReducer = fileApi.reducer;

export const {
  usePostFileMutation,
  useGetFileByParamsQuery,
  useDeleteFileMutation,
} = fileApi;
