import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import {
  ActiveUserProps,
  ActiveUserResponse,
} from "types/api/active-user/active-user";
import {
  SignInWithEmailAndPasswordProps,
  SignInWithEmailAndPasswordResponse,
} from "types/api/auth/sign-in-with-email-and-password";
import { baseQuery } from "types/api/base-query";

const { POST } = API_METHOD;

export const ACTIVE_PASSWORD_API_REDUCER_KEY = "activeUserApi";

export const activeUserApi = createApi({
  reducerPath: ACTIVE_PASSWORD_API_REDUCER_KEY,
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    activeUser: builder.mutation<ActiveUserResponse, ActiveUserProps>({
      query: (data) => {
        return {
          url: `${apiPath.ACTIVE_USER}${serialize(data)}`,
          method: POST,
          data,
        };
      },
    }),
  }),
});

export const activeUserApiReducer = activeUserApi.reducer;

export const { useActiveUserMutation } = activeUserApi;
