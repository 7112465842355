import "./style.scss";
import { Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import HeaderPageUser from "components/HeaderPage/HeaderPageUser";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/footer";
import FormRegister from "./Footer/form";

export const UserLayout: React.FC = () => {
  return (
    <div className="main-layout-user">
      <Layout>
        <div className="header">
          <HeaderPageUser />
        </div>
        <Layout>
          <Content id="content-page-user">
            <section className="content-page-user">
              <Outlet />

              {/* <FormRegister /> */}

              {/* <Footer /> */}
            </section>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
