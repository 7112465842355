//Exam
const POST_EXAM = "/test_paper/create";
const PUT_EXAM = "/test_paper/update";
const GET_LIST_EXAM = "/test_paper/paging_search";
const DELETE_EXAM = "/test_paper/delete";

export const exam = {
  POST_EXAM,
  PUT_EXAM,
  GET_LIST_EXAM,
  DELETE_EXAM,
};
