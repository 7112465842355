import { FIELD_TYPE } from "types/enums/field-type";
import { DataFormField } from "types/models/input-field-type";
const { INPUT, SELECT,INPUT_PASSWORD } = FIELD_TYPE;

export const loginForm: DataFormField[] = [
  
  {
    name: "email",
    type: INPUT,
    col: 1,
    label: "Email",
    rules: [
      {
        type: "email",
        message: "Email không hợp lệ!",
      },
      {
        required: true,
        message: `Hãy nhập thông tin cho trường này!`,
      },
    ],
  },
  {
    name: "password",
    type: INPUT_PASSWORD,
    col: 1,
    label: "Mật khẩu",
    rules: [
      
      {
        required: true,
        message: `Hãy nhập thông tin cho trường này!`,
      },
    ],
  },
];

export const registerForm: DataFormField[] = [
  {
    name: "fullName",
    type: INPUT,
    label: "Họ tên",
    col: 1,
    rules: [
      {
        required: true,
        message: `Hãy nhập thông tin cho trường này!`,
      },
    ],
  },
  {
    name: "phoneNumber",
    type: INPUT,
    col: 1,
    label: "Số điện thoại",
    rules: [
      {
        required: true,
        message: `Hãy nhập thông tin cho trường này!`,
      },
    ],
  },
  {
    name: "email",
    type: INPUT,
    col: 1,
    label: "Email",
    rules: [
      {
        type: "email",
        message: "Email không hợp lệ!",
      },
      {
        required: true,
        message: `Hãy nhập thông tin cho trường này!`,
      },
    ],
  },
  {
    name: "studiedAtSec",
    type: SELECT,
    col: 1,
    label: "Đã học tại SEC chưa?",
    rules: [
      {
        required: true,
        message: `Hãy nhập thông tin cho trường này!`,
      },
    ],
    options: [
      { label: "Đã học tại SEC", value: true },
      { label: "Chưa học tại SEC", value: false },
    ],
  },
];
