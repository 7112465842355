import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
export interface AdminState {
  topicId: string | null;
  examId: string | null;
  partId: string | null;
  questionId: number | string | null;
}

const initialState: AdminState = {
  topicId: null,
  examId: null,
  partId: null,
  questionId: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setTopicId: (state: AdminState, action: PayloadAction<string | null>) => {
      state.topicId = action.payload;
    },
    setExamId: (state: AdminState, action: PayloadAction<string | null>) => {
      state.examId = action.payload;
    },
    setPartId: (state: AdminState, action: PayloadAction<string | null>) => {
      state.partId = action.payload;
    },
    setQuestionId: (
      state: AdminState,
      action: PayloadAction<string | null>
    ) => {
      state.questionId = action.payload;
    },
  },
});

export const {setTopicId, setExamId, setPartId, setQuestionId} =
  adminSlice.actions;

export const adminReducer = persistReducer(
  {
    key: "rtk:admin",
    storage,
    whitelist: ["topic", "examId", "partId", "questionId"],
  },
  adminSlice.reducer
);
