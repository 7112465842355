import { baseQueryCandidate } from "./../../types/api/base-query-candidate";
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import { baseQuery } from "types/api/base-query";
import { ResultRes, ResultResponse } from "types/api/result";

const { POST, GET } = API_METHOD;

export const FILE_API_REDUCER_KEY = "gradingApi";

export const gradingApi = createApi({
  reducerPath: FILE_API_REDUCER_KEY,
  baseQuery: baseQueryCandidate,
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    gradingToeic: builder.mutation<ResultRes, any>({
      query: (data) => ({
        url: apiPath.POST_GRADING,
        method: POST,
        data,
      }),
    }),
    listFinishedExam: builder.query<any, any>({
      query: (data) => ({
        url: `${apiPath.LIST_EXAM_FOR_USER}${serialize(data)}`,
        method: GET,
        data,
      }),
    }),
    detailFinishedExam: builder.query<any, { id: string | undefined }>({
      query: (data) => ({
        url: `${apiPath.DETAIL_FINISH_EXAM}${serialize(data)}`,
        method: GET,
        data,
      }),
    }),
  }),
});

export const gradingReducer = gradingApi.reducer;

export const {
  useGradingToeicMutation,
  useListFinishedExamQuery,
  useDetailFinishedExamQuery,
} = gradingApi;
