import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { candidateAPI } from "store/api/candidate";
import { authAPI } from "store/api/auth";
import { SignInWithEmailAndPasswordResponse } from "types/api/auth/sign-in-with-email-and-password";
import { UserSliceProps } from "types/slices/user";

export const userInitialState: UserSliceProps = {
  isAuthenticated: undefined,
  accessToken: null,
  refreshToken: null,
  data: {
    id: 0,
    username: "",
    email: "",
    password: null,
    phoneNumber: "",
    fullName: "",
    studiedAtSec: false,
    isLocked: false,
    roles: [],
    accessToken: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    signOut: () => {
      return {
        ...userInitialState,
        waitAuthCheck: false,
        isAuthenticated: false,
        accessToken: null,
        accessTokenUser: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      //admin
      authAPI.endpoints.signInWithEmailAndPassword.matchFulfilled,
      (state, action: PayloadAction<SignInWithEmailAndPasswordResponse>) => {
        state.isAuthenticated = true;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        localStorage.setItem("accessToken", action.payload.accessToken);
        localStorage.setItem("refreshToken", action.payload.refreshToken);
        state.data = action.payload;
      }
    );
    builder.addMatcher(
      authAPI.endpoints.signInWithEmailAndPassword.matchRejected,
      (state) => {
        state.isAuthenticated = false;
        localStorage.removeItem("accessToken");
      }
    );
    /**hết */
  },
});

export const selectUser = ({ user }: { user: UserSliceProps }) => user;

export const selectUserData = ({ user }: { user: UserSliceProps }) =>
  user?.data;

export const selectUserRoles = ({ user }: { user: UserSliceProps }) =>
  user?.data?.roles;

export const userActions = userSlice.actions;

export const userReducer = persistReducer(
  {
    key: "rtk:user",
    storage,
    whitelist: ["accessToken", "refreshToken"],
  },
  userSlice.reducer
);
