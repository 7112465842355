import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import { baseQuery } from "types/api/base-query";
const { POST, DELETE } = API_METHOD;
export const FILE_API_REDUCER_KEY = "gradingEssayApi";
export const gradingEssayApi = createApi({
  reducerPath: FILE_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Post"],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    putGradingEssay: builder.mutation<any, any>({
      query: (data) => ({
        url: apiPath.PUT_GRADING_ESSAY,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    putCommentGradingEssay: builder.mutation<any, any>({
      query: (data) => ({
        url: apiPath.PUT_COMMENT_GRADING_ESSAY,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    getListUserGradingByParams: builder.query<any, any>({
      query: (data) => {
        return {
          url: `${apiPath.GET_LIST_USER_GRADING}${serialize(data)}`,
        };
      },
      transformErrorResponse: (response: any) => response.error,
      providesTags: ["Post"],
    }),
    getListExamGradingByParams: builder.query<any, any>({
      query: (data) => {
        return {
          url: `${apiPath.GET_LIST_EXAM_GRADING}${serialize(data)}`,
        };
      },
      transformErrorResponse: (response: any) => response.error,
      providesTags: ["Post"],
    }),
    getDetailExamGradingById: builder.query<any, any>({
      query: (id) => ({
        url: `${apiPath.GET_DETAIL_EXAM_GRADING}?id=${id}`,
      }),
      transformErrorResponse: (response: any) => response.error,
      providesTags: ["Post"],
    }),
    deleleUserGrading: builder.mutation<any, any>({
      query: (id) => ({
        url: `${apiPath.DELETE_USER_GRADING}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
  }),
});

export const gradingEssayReducer = gradingEssayApi.reducer;

export const {
  usePutGradingEssayMutation,
  useGetListUserGradingByParamsQuery,
  useGetListExamGradingByParamsQuery,
  useGetDetailExamGradingByIdQuery,
  usePutCommentGradingEssayMutation,
  useDeleleUserGradingMutation,
} = gradingEssayApi;
