import HeaderPage from "components/HeaderPage/HeaderPage";
import { Outlet } from "react-router-dom";
import "./style.scss";
import { Layout } from "antd";
import BreadcrumbCustom from "components/HeaderPage/BreadcrumbCustom";
import { useAppSelector } from "hooks";
import SiderCustom from "layouts/sider/sider";
import { Content } from "antd/es/layout/layout";

export const MainLayout = () => {
  const breadCrumb = useAppSelector((state) => state.global.breadCrumb);

  return (
    <div className="main-layout">
      <Layout>
        <SiderCustom />
        <Layout>
          <div className="header">
            <HeaderPage />
          </div>
          <Content>
            <BreadcrumbCustom _items={breadCrumb} />

            <div className="content-page">
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
