import { Navigate } from "react-router-dom";
import { ROUTER_ROUTE, USER_ROUTE } from "./routers";
import { MainLayout } from "layouts";
import lazy from "utils/lazy";
import ProtectedOutlet from "./protected-outlet";
import RoleOutlet from "./role-outlet";
import { ROLE } from "types/roles";
import { UserLayout } from "layouts/layoutUser";
import ProtectedOutletCandidate from "./protected-outlet-user";
// import Error404 from "pages/error/404";
// import Course from "pages/Course/Course";

const MainRoutes = [
  {
    path: USER_ROUTE.HOME,
    element: <Navigate to={USER_ROUTE.TEST_LIST} replace />,
  },
  {
    path: ROUTER_ROUTE.LOGIN,
    element: lazy({ path: "AdminPage/Login/Login" }),
  },
  {
    path: "",
    element: <UserLayout />,
    children: [
      {
        path: USER_ROUTE.TEST_LIST,
        element: lazy({ path: "UserPage/Home/index" }),
      },
      {
        path: USER_ROUTE.DETAIL_EXAM,
        element: lazy({ path: "UserPage/Home/DetailExam/index" }),
      },
      {
        path: "",
        element: <ProtectedOutletCandidate />,
        children: [
          {
            path: USER_ROUTE.QUESTION_LIST,
            element: lazy({
              path: "UserPage/Home/DetailExam/ContentPart/index",
            }),
          },
          {
            path: USER_ROUTE.RESULT_EXAM,
            element: lazy({ path: "UserPage/Home/ResultExam/index" }),
          },
          {
            path: USER_ROUTE.GRADE_EXAM_LIST,
            // element: <Course />,
            element: lazy({
              path: "UserPage/Home/ResultExam/GradeExamList/index",
            }),
          },
          {
            path: USER_ROUTE.GRADE_EXAM_DETAIL,
            // element: <Course />,
            element: lazy({
              path: "UserPage/Home/ResultExam/GradeExamList/GradeExamDetail",
            }),
          },
        ],
      },

      /**
       * Error
       */
      {
        path: ROUTER_ROUTE.ERROR_404,
        element: lazy({ path: "error/404" }),
        // element: <Error404 />,
      },
      /**
       * ALL
       */
      {
        path: "*",
        element: <Navigate to={ROUTER_ROUTE.ERROR_404} replace />,
      },
    ],
  },

  // {
  //   path: USER_ROUTE.ERROR_404,
  //   element: lazy({ path: "error/404" }),
  // },
  // /**
  //  * ALL
  //  */
  // {
  //   path: "*",
  //   element: <Navigate to={ROUTER_ROUTE.ERROR_404} replace />,
  // },
  {
    key: "private",
    path: "",
    element: <ProtectedOutlet />,
    children: [
      //layout for role admin
      {
        path: "",
        element: <RoleOutlet listRoleRequired={[ROLE.ADMIN]} />,
        children: [
          {
            path: "",
            element: <MainLayout />,
            children: [
              // {
              //   path: ROUTER_ROUTE.COURSE,
              //   // element: <Course />,
              //   element: lazy({ path: "AdminPage/Course/Course" }),
              // },
              // {
              //   path: ROUTER_ROUTE.TOPIC_LIST,
              //   // element: <Course />,
              //   element: lazy({ path: "AdminPage/Course/Topic/index" }),
              // },
              {
                path: ROUTER_ROUTE.EXAM,
                // element: <Course />,
                element: lazy({ path: "AdminPage/Exam/index" }),
              },
              /**
                Từng phần thi của 1 đề thi
                */
              {
                path: ROUTER_ROUTE.PART,
                // element: <Course />,
                element: lazy({
                  path: "AdminPage/Part/index",
                }),
              },
              {
                path: ROUTER_ROUTE.QUESTION,
                // element: <Course />,
                element: lazy({
                  path: "AdminPage/Question/index",
                }),
              },
              {
                path: ROUTER_ROUTE.QUESTION_DETAIL,
                // element: <Course />,
                element: lazy({
                  path: "AdminPage/QuestionDetail/index",
                }),
              },
              {
                path: ROUTER_ROUTE.GRADE_USER_LIST,
                // element: <Course />,
                element: lazy({
                  path: "GradePage/GradeUserList",
                }),
              },
              {
                path: ROUTER_ROUTE.GRADE_EXAM_LIST,
                // element: <Course />,
                element: lazy({
                  path: "GradePage/GradeExamList",
                }),
              },
              {
                path: ROUTER_ROUTE.GRADE_EXAM_DETAIL,
                // element: <Course />,
                element: lazy({
                  path: "GradePage/GradeExamDetail",
                }),
              },
              {
                path: ROUTER_ROUTE.USER,
                // element: <Course />,
                element: lazy({
                  path: "AdminPage/User/index",
                }),
              },
              {
                path: ROUTER_ROUTE.CATALOG,
                // element: <Course />,
                element: lazy({
                  path: "AdminPage/Catalog/index",
                }),
              },

              /**
               * Error
               */
              {
                path: ROUTER_ROUTE.ERROR_404,
                element: lazy({ path: "error/404" }),
                // element: <Error404 />,
              },

              /**
               * ALL
               */
              {
                path: "*",
                element: <Navigate to={ROUTER_ROUTE.ERROR_404} replace />,
              },
            ],
          },
        ],
      },
    ],
  },

  // {
  //   path: ROUTER_ROUTE.ERROR_404,
  //   element: lazy({ path: "error/404" }),
  // },
];

export default MainRoutes;
