// import { JwtPayload, jwtDecode } from "jwt-decode";

import { JwtPayload, jwtDecode } from "jwt-decode";

const checkToken = (accessToken: string | null): boolean => {
  /**
   * Check if access token is does not exist
   */
  if (!accessToken) {
    return false;
  }

  /**
   * Check if access token is not valid time
   */
  const decoded: JwtPayload = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  if (decoded.exp && decoded.exp < currentTime) {
    console.log("Hết hạn");

    return false;
  }
  // 1720890128 1720890583.631
  return true;
};

export default checkToken;
