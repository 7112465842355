import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { API_METHOD } from "types/api-method";
import {
  SignInWithEmailAndPasswordProps,
  SignInWithEmailAndPasswordResponse,
} from "types/api/auth/sign-in-with-email-and-password";
import { baseQuery } from "types/api/base-query";

const { POST } = API_METHOD;

export const CANDIDATE_API_REDUCER_KEY = "candidateApi";

export const candidateAPI = createApi({
  reducerPath: CANDIDATE_API_REDUCER_KEY,
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    signInCandidate: builder.mutation<
      SignInWithEmailAndPasswordResponse,
      SignInWithEmailAndPasswordProps
    >({
      query: (data) => {
        return {
          url: apiPath.SIGN_IN_CSNDIDATE,
          method: POST,
          data,
        };
      },
    }),
    signUpCandidate: builder.mutation<
      SignInWithEmailAndPasswordResponse,
      SignInWithEmailAndPasswordProps
    >({
      query: (data) => {
        return {
          url: apiPath.SIGN_UP_CSNDIDATE,
          method: POST,
          data,
        };
      },
    }),
  }),
});

export const candidateAPIReducer = candidateAPI.reducer;

export const { useSignInCandidateMutation, useSignUpCandidateMutation } =
  candidateAPI;
