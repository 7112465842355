import { Form, Row, Space, Tag } from "antd";
import InputFields from "components/InputFields/InputFields";
import ModalCustom, {
  ModalCustomHandle,
} from "components/ModalCustom/ModalCustom";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { loginForm, registerForm } from "./config";
import { useSignInWithEmailAndPasswordMutation } from "store/api/auth";
import { useNavigate } from "react-router-dom";
import { Notification } from "components";
import { BulbOutlined } from "@ant-design/icons";
import {
  useSignInCandidateMutation,
  useSignUpCandidateMutation,
} from "store/api/candidate";
import { CustomError } from "types/api/base-query";

interface Props {
  type: "REGISTER" | "LOGIN";
  nextToPage?: string;
}

const ModalLoginCandidate = forwardRef((props: Props, ref) => {
  const { type, nextToPage } = props;
  const navigate = useNavigate();
  const loginCandidateRef = useRef<ModalCustomHandle>(null);
  const [form] = Form.useForm();
  const [signInCandidate, { error, isSuccess, isLoading }] =
    useSignInCandidateMutation();

  const [
    signUpCandidate,
    {
      error: errorSignUp,
      isSuccess: isSuccessSignUp,
      isLoading: isLoadingSignUp,
    },
  ] = useSignUpCandidateMutation();

  const handleLogin = async () => {
    try {
      const value = await form.validateFields();
      if (type === "LOGIN") {
        await signInCandidate(value);
      } else await signUpCandidate(value);
      if (nextToPage) {
        navigate(nextToPage);
      }
    } catch (error) {}
  };

  useImperativeHandle(ref, () => ({
    open: () => loginCandidateRef.current?.openModal(),
  }));

  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      handleLogin();
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [type]);
  useEffect(() => {
    const errSignIn = error as CustomError;
    console.log("🚀 ~ useEffect ~ errSignIn:", errSignIn);
    const errSignUp = errorSignUp as CustomError;

    if (errSignIn?.status === 401) {
      return Notification({
        message:
          errSignIn?.data?.message ||
          errSignIn?.data ||
          "Bạn không có quyền truy cập!",
        status: "error",
      });
    }

    if (errSignUp || errSignIn) {
      return Notification({
        status: "error",
        message:
          errSignIn?.data?.message ||
          errSignIn?.data ||
          errSignUp?.data ||
          "Kết nối thất bại",
      });
    }
  }, [error, errorSignUp]);

  useEffect(() => {
    if (isSuccess || isSuccessSignUp) {
      Notification({
        status: "success",
        message: `${type === "LOGIN" ? "Đăng nhập" : "Đăng kí"}  thành công`,
      });
      loginCandidateRef.current?.closeModal();
    }
  }, [isSuccess, isSuccessSignUp]);

  return (
    <div className="login-candidate" onKeyDown={(e) => handleKeyDown(e)}>
      <ModalCustom
        ref={loginCandidateRef}
        onOk={handleLogin}
        title={`${
          type === "LOGIN" ? "Đăng nhập" : "Đăng ký thông tin"
        } thi thử trực tuyến`.toLocaleUpperCase()}
        okText={
          type === "LOGIN"
            ? "Đăng nhập".toLocaleUpperCase()
            : "Đăng ký".toLocaleUpperCase()
        }
        cancelText="HUỶ BỎ"
        loading={isLoading || isLoadingSignUp}
        width={"50%"}
      >
        <Space
          direction="vertical"
          wrap
          style={{ width: "100%" }}
          size={"large"}
        >
          <Form form={form}>
            <Row gutter={[16, 8]}>
              <InputFields data={type === "LOGIN" ? loginForm : registerForm} />
            </Row>
          </Form>
          <Tag color="gold" icon={<BulbOutlined />}>
            Lưu ý: Bạn phải điền đúng SĐT và Email để có thể nhận kết quả
          </Tag>
        </Space>
      </ModalCustom>
    </div>
  );
});

export default ModalLoginCandidate;
