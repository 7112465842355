import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { partApi } from "store/api/part";
import { DataExamResponse } from "types/api/exam";
import { ResponsePartProps } from "types/api/part";
interface Props {
  data: DataExamResponse;
}

export const userInitialState: Props = {
  data: {
    id: null,
    testPaperSetId: null,
    testPaperSetName: "",
    courseId: null,
    courseName: "",
    testPaperName: "",
    totalTime: null,
    isVisible: false,
    testPaperPartSize: null,
    questionSize: null,
  },
};

export const detailExamSlice = createSlice({
  name: "detailExam",

  initialState: userInitialState,

  reducers: {
    setDetailExam: (state: Props, action: PayloadAction<DataExamResponse>) => {
      state.data = action.payload;
    },
  },
});

export const { setDetailExam } = detailExamSlice.actions;

export const detailExamReducer = persistReducer(
  {
    key: "rtk:detail-exam",
    storage,
    whitelist: ["detail-exam"],
  },
  detailExamSlice.reducer
);
