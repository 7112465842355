export const BREADCRUMB = {
  COURSE_LIST: "Khoá học",
  EXAM_LIST: "Đề",
  TOPIC_LIST: "Bộ đề",
  MARK_THE_POINT: "Chấm điểm",
  CATALOG: "Danh mục",
  CONTENT_PART: "Phần câu hỏi",
  QUESTION: "Câu hỏi",
  QUESTION_DETAIL: "Chi tiết câu hỏi",
  GRADE_USER_LIST: "Danh sách học viên thi",
  GRADE_EXAM_LIST: "Danh sách bài thi",
  GRADE_EXAM_DETAIL: "Chi tiết bài thi",
  USER: "Danh sách người dùng",
};
