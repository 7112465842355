const CREATE_COURSE = "/course/create";
const GET_COURSE = "/course/paging_search";
const PUT_COURSE = "/course/update";
const DELETE_COURSE = "/course/delete";

export const course = {
  CREATE_COURSE,
  GET_COURSE,
  PUT_COURSE,
  DELETE_COURSE,
};
