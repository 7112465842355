import React, { useEffect, useState } from "react";

import { Col, MenuProps, Row } from "antd";
import { Menu, Layout } from "antd";
import {
  siderData as siderDataInit,
  menus as menusInit,
  pushSiderData,
} from "./sider.config";
import "./sider.scss";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { setCollapsed, setSiderKey } from "store/global/global.slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { TABKEYS } from "types/enums/tab-keys";
import { isValuable } from "helper/common";
import { RootState } from "store/store";
const { Sider } = Layout;

// const items: MenuProps["items"] = siderData;

//
const SiderCustom: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const siderKey = useAppSelector((e) => e.global.siderKey);
  const collapsed = useSelector((state: RootState) => state.global.collapsed);

  //********************************************* */

  const onClick: MenuProps["onClick"] = (e) => {
    dispatch(setSiderKey(e.key));
  };

  const [menus, setMenus] = useState(menusInit);
  const [items, setItems] = useState<MenuProps["items"]>(siderDataInit);

  useEffect(() => {
    const siderData: any[] = [];
    const checkIncludeKey = (menus): boolean => {
      let isInclude = false;
      if (menus.findIndex((menu) => menu.key == siderKey) > -1) {
        isInclude = true;
      } else {
        menus.forEach((menu) => {
          if (
            menu?.subItems?.findIndex((subItem) => subItem?.key == siderKey) >
            -1
          ) {
            isInclude = true;
          }
        });
      }
      return isInclude;
    };
    const setDefaultKey = () => {
      if (
        (!siderKey || !checkIncludeKey(menus)) &&
        (!localStorage.getItem("currentTab") ||
          localStorage.getItem("currentTab") == TABKEYS.CREATE_SOURCE)
      ) {
        dispatch(setSiderKey(menus?.[0]?.key));
        localStorage.setItem("currentSider", menus?.[0]?.key);
        if (
          isValuable(menus?.[0]?.link) ||
          isValuable(menus?.[0]?.subItems?.[0]?.link)
        ) {
          navigate(menus?.[0]?.link ?? menus?.[0]?.subItems?.[0]?.link);
        }
      }
    };
    pushSiderData(menus, siderData);
    // if (!window.REACT_APP_BE_URL.includes("alina")) {
    //   setDefaultKey();
    // }
    setDefaultKey();

    setItems([...siderData]);
  }, [menus]);
  return (
    <Sider
      className="sider"
      theme="light"
      collapsible
      // collapsedWidth="70"
      breakpoint="md"
      // width={250}
      collapsed={collapsed}
      onCollapse={(boolean, type) => {
        if (boolean) {
          dispatch(setCollapsed(boolean));
        }
      }}
      trigger={null}
    >
      <div className="logo-container">
        <Row className="sider-logo-box" justify="center" align={"middle"}>
          <Col>
            {/* <Space>
              {!collapsed && (
                <div className="sider-logo-title">
                  <img src={logoFullWhite} alt="" />
                </div>
              )}
              {collapsed && <img src={logoIconWhite} alt="" />}
            </Space> */}
          </Col>
        </Row>
      </div>
      <Menu
        onClick={onClick}
        mode="inline"
        inlineIndent={10}
        items={items}
        selectedKeys={[siderKey]}
      />
    </Sider>
  );
};

export default SiderCustom;
