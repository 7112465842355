//Exam
const POST_PART = "/test_paper_part/create";
const PUT_PART = "/test_paper_part/update";
const GET_LIST_PART = "/test_paper_part/paging_search";
const DELETE_PART = "/test_paper_part/delete";

export const part = {
  POST_PART,
  PUT_PART,
  GET_LIST_PART,
  DELETE_PART,
};
