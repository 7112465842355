import { candidate } from "../../constants/apiPath/candidate";
import axios, { AxiosError } from "axios";

import convertNullToUndefined from "utils/convert-null-to-undefined";
import { UserSliceProps } from "types/slices/user";
import { apiPath } from "constants/apiPath";
import { CandidateSliceProps } from "types/slices/candidate";
import { API_METHOD } from "types/api-method";

// const { BASE_URL } = ROUTER_ROUTE;
type RootState = {
  user: UserSliceProps;
  candidate: CandidateSliceProps;
};
export const baseInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
});
export interface CustomError {
  status?: number;
  data?: any;
}
const handleCall = async (requestOpts) => {
  try {
    console.log(requestOpts);
    const token = localStorage.getItem("accessToken");

    const result = await baseInstance({
      ...requestOpts,
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    });
    return { data: convertNullToUndefined(result.data) };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};
const axiosBaseQuery =
  (): any =>
  async (requestOpts, { getState }) => {
    try {
      console.log(requestOpts);
      const token = localStorage.getItem("accessToken");
      // const token = (getState() as RootState)?.user?.accessToken;

      const result = await baseInstance({
        ...requestOpts,
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      });
      return { data: convertNullToUndefined(result.data) };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };
const axiosBaseWithReauthQuery =
  (): any =>
  async (requestOpts, { getState }) => {
    let result = await handleCall(requestOpts);
    if (result?.error && result?.error.status === 401) {
      await handleRefreshToken();
      result = await handleCall(requestOpts);
    } else {
    }
    return result;
  };
const handleRefreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const res = await baseInstance({
      method: API_METHOD.POST,
      url: apiPath.REFRESH_TOKEN,
      data: { refreshToken: refreshToken },
    });

    localStorage.setItem("accessToken", res?.data?.accessToken);
    localStorage.setItem("refreshToken", res?.data?.refreshToken);
    return true;
  } catch (error) {
    return false;
  }
};
export const baseQuery = axiosBaseQuery();
export const baseQueryWithReauth = axiosBaseWithReauthQuery();
export { handleRefreshToken };
