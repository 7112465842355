import { candidate } from "../../constants/apiPath/candidate";
import axios, { AxiosError } from "axios";

import convertNullToUndefined from "utils/convert-null-to-undefined";
import { UserSliceProps } from "types/slices/user";
import { ROUTER_ROUTE } from "routers/routers";
import { CandidateSliceProps } from "types/slices/candidate";

// const { BASE_URL } = ROUTER_ROUTE;
type RootState = {
  candidate: CandidateSliceProps;
};
export const baseInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
});
export interface CustomError {
  status?: number;
  data?: any;
}
const axiosBaseQuery =
  (): any =>
  async (requestOpts, { getState }) => {
    try {
      console.log(requestOpts);
      const token = (getState() as RootState)?.candidate?.accessToken;
      console.log("🚀 ~ token:", token);

      const result = await baseInstance({
        ...requestOpts,
        headers: {
          Authorization: token ? `Bearer ${token}` : null,
        },
      });

      return { data: convertNullToUndefined(result.data) };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const baseQueryCandidate = axiosBaseQuery();
