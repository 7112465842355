const POST_TOPIC = "/test_paper_set/create";
const PUT_TOPIC = "/test_paper_set/update";
const GET_LIST_TOPIC = "/test_paper_set/paging_search";
const DELETE_TOPIC = "/test_paper_set/delete";
export const topic = {
  POST_TOPIC,
  PUT_TOPIC,
  GET_LIST_TOPIC,
  DELETE_TOPIC,
};
