import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import checkToken from "utils/check-token";
import { selectUser, userActions } from "store/auth/user";
import { USER_ROUTE } from "./routers";
import { useDispatch } from "react-redux";
import { candidateActions, selectCandidate } from "store/auth/candidate";

const ProtectedOutletCandidate: FC = () => {
  const { accessToken } = useAppSelector(selectCandidate);
  const navigate = useNavigate();
  const [access, setAccess] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const isValidToken = checkToken(accessToken);

      if (isValidToken) {
        setAccess(isValidToken);
      } else {
        navigate(USER_ROUTE.HOME);
        dispatch(candidateActions.signOut());
      }
    } catch (error) {}
  }, [accessToken]);

  useEffect(() => {
    if (!access) {
      navigate(USER_ROUTE.HOME);
    }
  }, [access, navigate]);

  return <Outlet />;
};

export default ProtectedOutletCandidate;
