//Exam
const POST_FILE = "/file/upload_file";
const GET_FILE = "/file/get_file";
const DELETE_FILE = "/file/delete";

export const file = {
  POST_FILE,
  GET_FILE,
  DELETE_FILE,
};
